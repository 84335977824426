













































































































.itemGame {
  cursor: pointer;
  width: 100%;
  position: relative;
  user-select: none;
  margin-bottom: 20px;
  &:hover {
    .itemGame-box {
      .img {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
  .tag-wrap {
    position: absolute;
    z-index: 1;
    top: 0.875rem;
    left: 0.875rem;
    display: flex;
    .tag {
      font-weight: bold;
      width: 2.8125rem;
      height: 2.8125rem;
      line-height: 2.8125rem;
      text-align: center;
      z-index: 1;
      font-size: 0.75rem;
      color: rgb(245, 246, 247);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: scale(0.8);
      transform-origin: 50% 0px;
      margin-left: -0.4375rem;
      background-image: url('~@/assets/images/home/top.png');
    }
  }
  .itemGame-box {
    width: 100%;
    padding-top: 100%;
    position: relative;
    display: block;
    border-radius: 1.25rem;
    overflow: hidden;
    transform: translateZ(0px);
    text-decoration: none;
    &.comingSoon {
      &::after {
        content: 'Coming soon...';
        position: absolute;
        right: 0rem;
        top: 0px;
        color: rgba(245, 246, 247, 0.8);
        text-align: right;
        padding: 0.625rem 0.875rem 0px 0px;
        z-index: 9;
      }
      &::before {
        content: '';
        position: absolute;
        inset: 0px;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
      }
    }
    .img {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease 0s;
      background: rgb(49, 52, 60);
      transition: 0.3s;
      outline: none;
      * {
        transition: 0.3s;
        outline: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .hover-mask {
      position: absolute;
      transition: all 0.3s linear 0s;
      left: 0%;
      top: 0%;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      font-size: 0.75rem;
      opacity: 0;
      &:hover {
        opacity: 1;
      }
      .house-edge {
        color: rgb(245, 246, 247);
        text-align: right;
        padding: 0.625rem 0.875rem 0px 0px;
      }
      svg {
        color: rgba(245, 246, 247, 0.9);
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -1.5rem;
        margin-top: -1.5rem;
        transform: scale(1.3);
        transition: transform 0.3s ease 0s;
        @media (max-width: 1199px) {
          width: 2rem;
          height: 2rem;
          left: 55%;
          top: 60%;
        }
        @media (max-width: 991px) {
          left: 50%;
          top: 50%;
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }
  .itemGameBottom {
    margin: 0.3125rem 0.625rem 0px;
    color: rgba(153, 164, 176, 0.6);
    height: 1.5625rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    .game-name {
      word-break: break-all;
      flex: 1 1 auto;
      color: #fff;
      font-weight: bold;
      text-align: center;
    }
    .help-btn-wrap {
      position: relative;
      width: 1.125rem;
      height: 1.125rem;
      button {
        opacity: 0.6;
        border: none;
        background: none;
        padding: 0px;
        svg {
          color: rgba(153, 164, 176, 0.6);
        }
      }
    }
  }
}
